<!--用户登录-->
<template>
  <!--路径s-->
  <div class="path">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="24" :md="22" :lg="20" >
        <span><router-link to="/"> 首页</router-link></span> &nbsp; &gt; &nbsp;<a :href="`/news/index?id=${newsData.categoryId}`"> {{newsData.categoryId_name}} </a>&nbsp; &gt; &nbsp; 正文
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <!--路径s--> 
  <div class="box-p">
    <el-row :gutter="0">
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :xs="0" :sm="1" :md="1" :lg="1" ></el-col>
      <el-col :xs="24" :sm="22" :md="20" :lg="18">
        <div class="newslist">
            <div class="news_title">{{newsData.contentTitle}}</div>   
            <div class="news_tit">
              <span v-if="newsData.contentSource">来源：{{newsData.contentSource}}</span>
              <span v-if="newsData.contentAuthor">作者：{{newsData.contentAuthor}}</span>
              <span v-if="newsData.contentDatetime">发布时间：{{formatDate(newsData.contentDatetime)}}</span>
              <span v-if="newsData.contentHit">浏览：{{newsData.contentHit}}</span>
            </div>
            <div class="news_content" v-html="newsData.contentDetails"></div>
        </div>
      </el-col>
      <el-col :xs="0" :sm="1" :md="1" :lg="1"></el-col>
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
    </el-row>
  </div>  
</template>

<script>
import {formatDate} from '@/utils/datetime_utils.js'
export default {
  name: 'news',
  data () {
    return {
      newsData: {
        contentTitle: '',
        contentDetails: '',
        contentDatetime: '',
        contentSource: '',
        contentAuthor: '',
        categoryId_name: '',
        contentHit: ''
      }
    }
  },
  mounted () {
    var id = this.$route.query.id
    if(id === undefined || id === '') {
      this.$message.error('参数错误，打开页面失败。')
      return
    }    
    this.getData(id)
  },
  methods: {   
    async getData (id) {
      // const loading = this.$loading({
      //   lock: true,
      //   text: '正在加载中...',
      //   // spinner: 'el-icon-loading',
      //   // background: 'rgba(0, 0, 0, 0.7)'
      // })
      const params = 'id=' + id
      // console.log('params=', params)
      const { data: ret } = await this.$http.post1(this.$api.newsshow, params) 
      // console.log('ret=', ret)
      if (ret.code === 1) {
        if(ret.data === null) {
          this.$message.error('没有内容！') 
          return
        }
        this.newsData = ret.data       
        console.log('data=', this.newsData)
      } else {
        this.$message.error(ret.msg)        
      }
      // loading.close()
    },
    // 显示时间格式'yyyy-MM-dd hh:mm:ss'
    formatDate(time){
      return formatDate(time,'yyyy-MM-dd hh:mm')
    }    
  }
}
</script>

<style scoped>
.news_title {
  display: block;
  padding: 1.0rem 0;
  font-size: 26px;
  font-weight: bold;
  color:#333;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
}
.news_tit {
  display: block;
  padding: 0.5rem 0;
  font-size: 14px;
  color:#999;
  text-align: center;
}
.news_tit span {
  padding: 0 10px;
}
.news_content {
  display: block;
  padding: 1.5rem 0;
  text-align: justify !important;
  line-height: 180%;
  min-height: 400px;
  color:#666;
}
.news_content img {
  max-width: 100%;
  height: auto;
}
</style>